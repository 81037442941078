import { ReactNode } from "react"
import { Link } from "react-router-dom"

import { UserService, AccountResponseDto } from "../../app/service/user"

import { STATUS_TAGS } from "../../app/utils/configs"

import { Title } from "../../partial/Title"
import { Table, TableConfiguration } from "../../components/common/table/Table"
import Tag from "../../components/common/Tag"
import UserAvatar from "../../components/common/primitive/UserAvatar"

export const Users = () => {
  const table: TableConfiguration = {
    data: {
      serviceMethod: new UserService().list,
      // ...(filter && { serviceMethodPayload: { status: filter } }),
    },

    render: (item: AccountResponseDto): ReactNode => {
      return (
        <Link to={`/users/${item.username}`} className="flex w-full">
          <div className="px-3 w-[30%] flex items-center">
            <UserAvatar
              url={item?.cover}
              name={item?.firstName + " " + item?.lastName}
              className="mr-3 w-[37px] h-[37px] rounded-full"
              size={37}
            />
            {item.firstName} {item.lastName}
          </div>
          <div className="px-3 w-[20%] text-[#757E8C]">{item.username}</div>
          <div className="px-3 w-[20%] text-[#757E8C]">{item.email}</div>
          <div className="px-3 w-[10%]">
            <Tag tagColor={STATUS_TAGS[item.status]} title={item.status} />
          </div>
        </Link>
      )
    },

    title: "Users",

    isTable: true,

    columns: [
      {
        title: "Name",
        classNames: "w-[30%]",
      },
      {
        title: "Username",
        classNames: "w-[20%]",
      },
      {
        title: "Email Address",
        classNames: "w-[20%]",
      },
      {
        title: "Status",
        classNames: "w-[10%]",
      },
    ],
  }

  return (
    <div className="p-5">
      <Title right={<div className="flex gap-5 my-2"></div>} />

      <Table table={table} />
    </div>
  )
}
