import Card from "../../components/common/Card"
import CardItem from "../../components/common/CardItem"

import {
  SimpleTable,
  TableConfiguration,
} from "../../components/common/table/SimpleTable"

import { AccountResponseDto } from "../../app/service/user"
import { TOPOLOGICA_URL } from "../../app/utils/configs"

export type QuizzРHistoryResponseDto = {
  name: string
  date: string
}

const visitedPlaces: Array<QuizzРHistoryResponseDto> = [
  {
    name: "Name 1",
    date: "August 11, 2022",
  },
  {
    name: "Name 2",
    date: "August 9, 2022",
  },
  {
    name: "Name 3",
    date: "August 9, 2022",
  },
  {
    name: "Name 4",
    date: "August 6, 2022",
  },
  {
    name: "Name 5",
    date: "August 1, 2022",
  },
]

const visitedPlacesTable = {
  columns: [
    {
      title: "Name",
      classNames: "text-black  w-[50%]",
    },
    { title: "Date", classNames: "text-gray-300 w-[50%]" },
  ],
}

const friendsTable: TableConfiguration = {
  columns: [
    { title: "Friend Name", classNames: "text-black w-[50%]" },
    { title: "Date", classNames: "text-gray-300 w-[50%]" },
  ],
}

export const UserAnalytics = ({ user }: { user?: AccountResponseDto }) => {
  const url=TOPOLOGICA_URL+'/user/'+user?.username;

  return (
    <div>
      <Card className="my-5">
        <div className="flex">

          <div className="w-[50%] py-1">
            <CardItem
              title="Name"
              subtitle={user?.firstName + " " + user?.lastName}
            />
            <CardItem
              title="Username"
              subtitle={user?.username}
            />
            <CardItem title="Email" subtitle={user?.email} border={true} />
            <CardItem title="Status" subtitle={user?.status} />
          </div>

          <div className="w-[50%] py-1">
            <CardItem
                title="URL"
                subtitle={<a href={url} target="_blank" className="text-[#0AA2BF]">{url}</a>}
              />
          </div>

        </div>
      </Card>

      <div className="my-5 grid grid-cols-3 gap-4">
        <Card title="Places Visited " style="big">
          <SimpleTable table={visitedPlacesTable} items={visitedPlaces} />
        </Card>
        <Card title="Places Planned" style="big">
          <SimpleTable table={visitedPlacesTable} items={visitedPlaces} />
        </Card>
        <Card title="Friends" style="big">
          <SimpleTable table={friendsTable} items={visitedPlaces} />
        </Card>
      </div>
    </div>
  )
}
