import { AxiosResponse } from "axios"
import BaseApiService from "./@/base.api.service"

import { ListRequestDto, ListResponseDto } from "./dto"
import { PlaceImage } from "./place"

export type CountryFullResponseDto = {
    country: CountryResponseDto,
    images: PlaceImage[]; 
}

export type CountryResponseDto = {
  id: string
  slug: string | null
  name: string
  continent: string
  nid: number
  createdAt: Date
  updatedAt: Date
  lat: string,
  lng: string,
  countryCode: string | null
  travelInfo: string | null
  overview: string | null
  statistics: string | null
  capital: string | null
}

export type CountryCUDto = {
  id: string;
  name: string;
  lat: string,
  lng: string,
  countryCode: string | null
  travelInfo: string | null
  overview: string | null
  statistics: string | null
  capital: string | null
}

export class CountryService {

  async get(id: string): Promise<AxiosResponse<CountryFullResponseDto>> {
    return BaseApiService.get("/countries/get/" + id)
  }

  async list(payload?: ListRequestDto): Promise<AxiosResponse<ListResponseDto<CountryResponseDto>[]>> {
    return BaseApiService.post("/countries/list", payload)
  }

  async createOrUpdate(payload: CountryCUDto): Promise<AxiosResponse<CountryResponseDto>> {
    return BaseApiService.post("/countries/createOrUpdate/", payload)
  }

  async delete(id: string): Promise<AxiosResponse<void>> {
    return BaseApiService.delete("/countries/delete/" + id)
  }
}
