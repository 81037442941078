import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Alert from "react-s-alert"

import { dateFormat } from "../../app/utils/index"

import { CountryResponseDto, CountryService } from "../../app/service/country"

import { CountryForm } from "./CountryForm"
import { SplashButton } from "../../partial/button/SplashButton"
import { FormHeader } from "../../partial/Form"
import { ProfileTop } from "../../partial/ProfileTop"

export const CountryView = () => {
  const history = useHistory()
  const [country, setCountry] = useState<CountryResponseDto>()
  const { id } = useParams<{id: string}>()

  useEffect(() => { fetch() }, [])

  const fetch = () => {
    new CountryService().get(id).then((r) => {
      setCountry(r.data.country)
    })
  }

  const onSave = (country: CountryResponseDto) => {

    const payload={
      id: country.id,
      name: country.name,
      lat: country.lat,
      lng: country.lng,
      countryCode: country.countryCode,
      travelInfo: country.travelInfo,
      overview: country.overview,
      statistics: country.statistics,
      capital: country.capital
    }

    new CountryService().createOrUpdate(payload).then((r) => {
      setCountry(r.data)
      Alert.info("Country was succesfully updated")
    }).catch(e=>{
      Alert.warning("Country while updating place")
    })

  }

  return (
      <div className="p-5">
        {country && (
          <>
            <ProfileTop
              profile={{ name: country.name, title: "Created at: " + dateFormat(country.createdAt) + ", Updated at: " + dateFormat(country.updatedAt) }}
              right={ <span className="flex gap-3"> <SplashButton title="Back" icon="arrow-left-circle" onClick={() => { history.goBack() }} /> </span> }
            />
        
            <div className="my-5">
              <FormHeader title="Country Settings">
                <CountryForm item={country} onSave={(country) => onSave(country)} />
              </FormHeader>
            </div>
          </>
        )}
      </div>
  )
}
