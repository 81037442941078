import Avatar from "boring-avatars"

const UserAvatar = ({
  url,
  size,
  name,
  className,
}: {
  url?: string
  size?: number
  name?: string
  className?: string
}) => {
  return (
    <span className={!url ? className : ""}>
      {url ? (
        <img src={url} className={className} />
      ) : (
        <Avatar
          size={size ? size : 50}
          name={name}
          variant="beam"
          colors={["#2B4FF1", "#5472F3", "#00A5FD", "#95A7F9", "#80D3FF"]}
        />
      )}
    </span>
  )
}

export default UserAvatar
