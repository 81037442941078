import React, { ReactNode } from "react"
import classNames from "classnames"

import UserAvatar from "../components/common/primitive/UserAvatar"
import Tag from "../components/common/Tag"

export const ProfileTop = ({
  profile,
  tagLeft = false,
  right,
}: {
  profile: { name: string; status?: string; title?: string; cover?: string }
  tagLeft?: boolean
  right?: ReactNode
}) => {
  return (
    <div className="flex items-center justify-between">
      <span className="flex items-center">
        {profile.cover && (
          <UserAvatar
            url={profile.cover}
            name={profile.name}
            className="mr-3 w-[80px] h-[80px] rounded-full"
            size={80}
          />
        )}
        <div
          className={classNames(
            tagLeft && ["flex", "justify-between", "w-100"],
            "ml-3"
          )}
        >
          <div className="text-4xl mb-2">{profile.name}</div>
          <div className="flex items-center">
            {profile.status && (
              <Tag title={profile.status} tagColor="greenTag" />
            )}
            {profile.title && (
              <div className="text-gray-500">{profile.title}</div>
            )}
          </div>
        </div>
      </span>
      {right && <span>{right}</span>}
    </div>
  )
}
