import { ReactNode, useEffect, useState } from "react"
import classNames from "classnames"

import { Title } from "../../../partial/Title"
import SearchInput from "./../input/SearchInput"
import Loader from "../../common/primitive/Loader"
import Tag from "../Tag"

import { ListResponseDto } from "../../../app/service/dto"

import { AxiosResponse } from "axios"
import { FilterDropdown } from "../../../partial/FilterDropdown"

export type TableConfiguration = {
  data?: {
    serviceMethod?: (payload: any) => Promise<AxiosResponse<any>>
    serviceMethodPayload?: any
  }
  render?: (item: any) => ReactNode

  columns?: Array<{
    title: string
    width?: string
    classNames?: string
  }>

  title?: string
  wrapperClass?: string

  tag?: {
    primary: { title: string }
  }

  search?: {
    disabled?: boolean
  }

  isTable?: boolean
}

export const Table = ({
  table,
}: // filter,
{
  table: TableConfiguration
  items?: any
  filter?: any
}) => {
  const [data, setData] = useState<ListResponseDto<any>>()
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    fetch()
  }, [search, filter])

  const fetch = () => {
    const data = table.data
    if (!data || !data.serviceMethod) return

    setLoading(true)

    console.log("serviceMethodPayload", data?.serviceMethodPayload)

    data
      ?.serviceMethod({ ...data.serviceMethodPayload, search: search })
      .then((r) => {
        console.log("r.data", r.data)
        setData(r.data)
        setLoading(false)
      })
      .catch((e) => console.log(e))
  }

  return (
    <div>
      <div
        className={classNames(
          "flex",
          table.title ? "justify-between" : "justify-end"
        )}
      >
        {table.title && (
          <div className="flex items-center mb-3">
            <Title title={table.title} className="text-xl !mb-0" />
            {table.tag?.primary && (
              <span className="ml-2">
                <Tag
                  title={`${data?.list.length || ""} ${
                    table.tag.primary.title
                  }`}
                  tagColor="blue"
                />
              </span>
            )}
          </div>
        )}

        <span className="flex items-center gap-5">
          {/* <FilterDropdown
            title={filter ? "Filter by " + filter.toLowerCase() : "Filter"}
            items={[
              {
                title: "All",
                icon: "layers",
                on: () => {
                  setFilter("")
                },
              },
              {
                title: "Active",
                icon: "",
                on: () => {
                  setFilter("ACTIVE")
                },
              },
              {
                title: "Inactive",
                icon: "",
                on: () => {
                  setFilter("INACTIVE")
                },
              },
              {
                title: "Pending",
                icon: "",
                on: () => {
                  setFilter("PENDING")
                },
              },
            ]}
          /> */}

          {!table.search?.disabled && (
            <SearchInput
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          )}
        </span>
      </div>

      {table.isTable && (
        <div className="flex bg-black border-t rounded-t-md text-white p-2 px-4 ">
          {table.columns?.map((column, key) => (
            <div className={classNames(column.classNames, "px-3")} key={key}>
              {column.title}
            </div>
          ))}
        </div>
      )}

      <div className="flex w-full bg-[white]">
        <Loader state={loading} />
      </div>

      <div
        className={classNames(
          table.wrapperClass,
          table.isTable && "border rounded bg-white"
        )}
      >
        {data?.list.map((item: any, keyI: number) => (
          <div
            key={keyI}
            className={classNames(
              table.isTable &&
                "flex cursor-pointer bg-white text-black p-2 py-3 justify-between border-t border-b"
            )}
          >
            {table.render && table.render(item)}
          </div>
        ))}

        {data?.list.length === 0 && (
          <div className="flex justify-center p-4 text-[#757E8C]">
            There are no items{" "}
          </div>
        )}

        {/* <div className="flex bg-white text-black p-2 py-2 px-4  justify-between border-t items-center rounded-b-md">
          <span className="text-sm">Page 1 of X</span>
          <Button title="Next" onClick={() => {}} />
        </div> */}
      </div>
    </div>
  )
}
