import { AxiosResponse } from "axios"
import BaseApiService from "./@/base.api.service"

import { ImportRequestDto, ListResponseDto } from "./dto"

export interface TagDto {
  id: string
  label: string
  createdAt: Date
  updatedAt: Date
}

export type TagCUDto = {
  label: string
}

export class ImportService {
  async import(
    payload: ImportRequestDto
  ): Promise<AxiosResponse<ListResponseDto<TagDto>>> {
    return BaseApiService.post("/data/import", payload)
  }
}
