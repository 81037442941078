import { useForm } from "react-hook-form"

// @ts-ignore
import FeatherIcon from "feather-icons-react"
import { useAuth } from "../../app/providers/auth.provider"

import logo from "./../../assets/image/logo.svg"
import TextInput from "../../components/common/input/TextInput"

interface FormInputs {
  email: string
  password: string
}

export const Signin = () => {
  const auth = useAuth()

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    setError,
  } = useForm<FormInputs>({
    mode: "onTouched",
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const onSubmit = (data: FormInputs) => {
    auth.signin(
      { email: data.email.toLocaleLowerCase(), password: data.password },
      (data) => {},
      (error) => {
        setError("email", { type: "response" }, { shouldFocus: true })
        setError("password", { type: "response" })
      }
    )
  }

  return (
    <div className="flex h-screen w-screen">
      <div className="m-auto">
        <div className="pb-3 text-center">
          <img src={logo} alt="Basewell" className="h-[50px] inline-block" />
          <div className="text-2xl font-extrabold my-3">
            Sign in
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} noValidate className="flex2">
          <TextInput
            id="email"
            control={control}
            name="email"
            type="email"
            placeholder={"Email address"}
            error={errors.email?.message}
            rules={{
              required: String("signin.email.required"),
            }}
            className="rounded-t-md w-full py-2 px-3 border-gray-200 border-t border-r border-l"
          />
          <TextInput
            id="password"
            control={control}
            name="password"
            type="password"
            placeholder={"Password"}
            error={errors.password?.message}
            rules={{
              required: String("signin.password.required"),
              minLength: {
                value: 3,
                message: "signin.password.validation",
              },
            }}
            className="rounded-b-md w-full py-2 px-3 border-gray-200 border"
          />
          {isSubmitted &&
            (errors.email?.type.includes("response") ||
              errors.password?.type.includes("response")) && (
              <p className="text-center text-[red]">{"signin.error"}</p>
            )}
          <div className="text-center my-5">
            <button
              type="submit"
              className="rounded-md w-full bg-[#2B4FF1] text-white py-2 flex items-center justify-center"
            >
              <FeatherIcon icon="lock" size="14" />
              <span className="ml-2">Sign in</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
