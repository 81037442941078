import { AxiosResponse } from "axios"
import BaseApiService from "./@/base.api.service"
import { DashboardStats } from "./dto"

export class DataService {

  async dashboard(): Promise<AxiosResponse<DashboardStats>> {
    return BaseApiService.post("/data/dashboard/")
  }

  async slugs(): Promise<AxiosResponse<void>> {
    return BaseApiService.post("/data/slugs/generate/")
  }

  async count(): Promise<AxiosResponse<void>> {
    return BaseApiService.post("/data/count/")
  }

  async synch(): Promise<AxiosResponse<void>> {
    return BaseApiService.post("/data/synch/")
  }

  async aggregate(): Promise<AxiosResponse<void>> {
    return BaseApiService.post("/data/aggregate/")
  }


}
