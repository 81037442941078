import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Alert from "react-s-alert"

import { PlaceResponseDto, PlaceService } from "../../app/service/place"

import { dateFormat } from "../../app/utils/index"

import { PlaceForm } from "./PlaceForm"
import { SplashButton } from "../../partial/button/SplashButton"
import { FormHeader } from "../../partial/Form"
import { ProfileTop } from "../../partial/ProfileTop"

// import { PlaceAnalytics } from "./PlaceAnalytics"

export const PlaceView = () => {
  const history = useHistory()
  const [place, setPlace] = useState<PlaceResponseDto>()
  const { id } = useParams<{id: string}>()

  useEffect(() => { fetch() }, [])

  const fetch = () => {
    new PlaceService().get(id).then((r) => {
      setPlace(r.data)
    })
  }

  const onSave = (place: PlaceResponseDto) => {

    const payload={
      id: place.id,
      name: place.name,
      description: place.description,
      lat: place.lat,
      lng: place.lng,
      statVisited: place.statVisited
    }

    new PlaceService().createOrUpdate(payload).then((r) => {
      setPlace(r.data)
      console.log(r.data,'/places/'+r.data.slug);
      Alert.info("Place was succesfully updated")
    }).catch(e=>{
      Alert.warning("Error while updating place")
    })

  }

  return (
    <>
      <div className="p-5">
        {place && (
          <ProfileTop
            profile={{ name: place.name, title: "Created at: " + dateFormat(place.createdAt) + ", Updated at: " + dateFormat(place.updatedAt) }}
            right={ <span className="flex gap-3"> <SplashButton title="Back" icon="arrow-left-circle" onClick={() => { history.goBack() }} /> </span> }
          />
        )}

        {place && (
          <>
            <div className="my-5">
              <FormHeader title="Place Settings">
                <PlaceForm item={place} onSave={(place) => onSave(place)} />
              </FormHeader>
            </div>

            {/* <FormHeader title="Place Analitycs">
              <div className="my-5">
                <PlaceAnalytics item={place} />
              </div>
            </FormHeader> */}
          </>
        )}
      </div>
    </>
  )
}
