import Tag from "./Tag"
import classNames from "classnames"
import { ReactNode } from "react"

const CardItem = ({
  title,
  subtitle,
  tag,
  border = false,
  content,
}: {
  title: string | ReactNode
  subtitle?: string | ReactNode
  tag?: { title: string; style: string }
  border?: boolean
  content?: ReactNode
}) => {
  return (
    <div
      className={classNames(
        "py-4 flex items-center justify-between",
        border && "border-b border-t py-5 border-gray-200"
      )}
    >
      <div className="w-[70%]">
        <div className="font-extrabold text-md mb-1">{title}</div>
        <div className="font-extrabold text-sm text-gray-500">{subtitle}</div>
      </div>
      {tag && <Tag title={tag.title} tagColor={tag.style + "Tag"} />}
      {content}
    </div>
  )
}

export default CardItem
