import classNames from "classnames"

export type TableConfiguration = {
  columns: Array<{
    title: string
    classNames?: string
  }>
}

export const SimpleTable = ({
  table,
  items,
}: {
  table: TableConfiguration
  items: Array<any>
}) => {
  return (
    <>
      <div className="flex bg-white text-[#757E8C] border-b border-[#DDDDDD] py-4 justify-between">
        {table.columns.map((column) => (
          <div
            className={classNames(
              "pr-3",
              `w-[${column.classNames}%] font-bold`
            )}
          >
            {column.title}
          </div>
        ))}
      </div>
      {items.map((item, key) => (
        <div
          className="flex text-[#757E8C] border-b border-[#DDDDDD] py-3 justify-between"
          key={key}
        >
          <div className="">{item["name"]}</div>
          <div className="">{item["date"]}</div>
        </div>
      ))}
    </>
  )
}
