import { AxiosResponse } from "axios"
import BaseApiService from "../base.api.service"

import {
  AccountUpdateRequestDto,
  AccountResponseDto,
  AccountPasswordUpdateDto,
  AccountLocationData,
} from "./account.dto"

class AccountService {
  async get(id: string): Promise<AxiosResponse<AccountResponseDto>> {
    return BaseApiService.get("/account/get/" + id)
  }

  async update(
    payload: AccountUpdateRequestDto
  ): Promise<AxiosResponse<AccountResponseDto>> {
    return BaseApiService.patch("/account/update", payload)
  }

  async locationUpdate(
    payload: AccountLocationData
  ): Promise<AxiosResponse<void>> {
    return BaseApiService.patch("/account/location/update", payload)
  }

  async passwordUpdate(
    payload: AccountPasswordUpdateDto
  ): Promise<AxiosResponse<void>> {
    return BaseApiService.patch("/account/password/update", payload)
  }
}

export default new AccountService()
