import { useState } from "react"
import { Button } from "../../partial/button/Button"
import { FormRow } from "../../partial/Form"
import { TOPOLOGICA_URL } from "../../app/utils/configs"
import { TagResponseDto } from "../../app/service/tag"
import { Toggle } from "../../partial/input/Toggle"

export const TagForm = ({
  item,
  onSave,
}: {
  item: TagResponseDto
  onSave: (tag: TagResponseDto) => void
}) => {
  const [tag, setTag] = useState<TagResponseDto>(item)

  const url=TOPOLOGICA_URL+"/tag/"+tag.slug;

  console.log(tag);

  return (
    <>
      <FormRow title="URL">
          <a target="_blank" href={url} className="text-[#0AA2BF]">{url}</a>
      </FormRow>

      <FormRow title="Name">
        <input
          type="text"
          placeholder="Name"
          className="user-edit-item-input w-100"
          value={tag?.label}
          onChange={(e) => setTag({ ...tag, label: e.target.value })}
        />
      </FormRow>

      <FormRow title='Active'>
        <Toggle value={tag.active} onChange={(active) => setTag({ ...tag, active })} />
      </FormRow>

      <div className="user-edit-footer py-2">
        <Button title="Save" onClick={() => onSave(tag)} />
      </div>
    </>
  )
}
