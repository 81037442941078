import { AxiosResponse } from "axios"
import BaseApiService from "./@/base.api.service"

import { ListRequestDto, ListResponseDto } from "./dto"

export type TagResponseDto = {
  id: string
  slug: string | null
  label: string
  active: boolean
  createdAt: Date
  updatedAt: Date
}

export type TagCUDto = {
  label: string
}

export class TagService {
  async get(id: string): Promise<AxiosResponse<TagResponseDto>> {
    return BaseApiService.get("/tags/get/" + id)
  }

  async list(payload?: ListRequestDto): Promise<AxiosResponse<ListResponseDto<TagResponseDto>>> {
    return BaseApiService.post("/tags/list", payload)
  }

  async create(payload: TagCUDto): Promise<AxiosResponse<TagResponseDto>> {
    return BaseApiService.post("/tags/create/", payload)
  }

  async delete(id: string): Promise<AxiosResponse<void>> {
    return BaseApiService.delete("/tags/delete/" + id)
  }
}
