import classNames from "classnames"
import { ReactNode } from "react"

export const FormHeader = ({
  title,
  children,
  black = true,
}: {
  title?: string
  children: ReactNode
  black?: boolean
}) => {
  return (
    <div className={classNames("rounded-t-md")}>
      {title && (
        <div className="flex border-t text-white p-3 rounded-t-md bg-black">
          {title}
        </div>
      )}
      <div
        className={classNames(
          "bg-white p-4 pb-2 border border-gray-200",
          !black && "rounded-t-md",
          "rounded-b-md"
        )}
      >
        {children}
      </div>
    </div>
  )
}

export const FormRow = ({
  title,
  subtitle,
  children,
  noBorder = false,
  childClassName,
}: {
  title?: string
  subtitle?: string
  children: ReactNode
  noBorder?: boolean
  childClassName?: string
}) => {
  return (
    <>
      <div className="user-edit-item  flex items-center">
        <div className="user-edit-item-title w-[25%] flex flex-col items-start py-3">
          <div>{title}</div>
          {subtitle && <div className="mt-3 text-gray-500">{subtitle}</div>}
        </div>
        <div className={classNames("w-[75%]", childClassName)}>{children}</div>
      </div>
      {!noBorder && <hr></hr>}
    </>
  )
}
