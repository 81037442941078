import moment from "moment"

export const dateFormat = (date: Date | string | undefined) => {
  return moment(date).format("MMMM DD, YYYY")
}

export const validateEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}

export const imageUrl = (url: string, w?: number, h?: number) => {
  if (!url)
    url =
      "https://res.cloudinary.com/dkrx3lffc/image/upload/v1667304313/BASEWELL/ef3-placeholder-image_pgm9sn.jpg"

  if (w && h) {
    const replace = "/upload/h_" + h + ",w_" + w + "/"
    return url.replace("/upload/", replace)
  }
  return url
}
