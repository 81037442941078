import React, { useContext, createContext, useState } from "react"

import AuthService from "../service/@/auth/auth.service"
import { AccountResponseDto } from "../service/@/account/account.dto"
import TokenService from "../service/@/token.service"

import {
  SigninRequestDto,
  SigninResponseDto,
} from "../../app/service/@/auth/auth.dto"

interface IAuthContext {
  isLoggedIn: boolean
  signin(
    { email, password }: SigninRequestDto,
    handleSuccess: (_data: SigninResponseDto) => void,
    handleError: (err: Error) => void
  ): Promise<void>
  signout(): void
  account: AccountResponseDto | null
}

const authContext = createContext<IAuthContext>(null!)

function ProvideAuth({ children }: { children: React.ReactNode }) {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

function useAuth() {
  return useContext(authContext)
}

function useProvideAuth(): IAuthContext {
  const [isLoggedIn, setiIsLoggedIn] = useState(AuthService.isAuthenticated())

  const signin = async (
    { email, password }: SigninRequestDto,
    handleSuccess: (_data: SigninResponseDto) => void,
    handleError: (err: Error) => void
  ): Promise<void> => {
    AuthService.signin(
      { email, password },
      (data) => {
        setiIsLoggedIn(true)
        handleSuccess(data)
      },
      (err) => {
        setiIsLoggedIn(false)
        handleError(err)
      }
    )
  }

  const signout = () => {
    AuthService.signout()
    setiIsLoggedIn(false)
  }

  const account: AccountResponseDto | null = TokenService.getAccount()

  return {
    isLoggedIn,
    signin,
    signout,
    account,
  }
}

export { useAuth, ProvideAuth }
