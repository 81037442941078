import { useState } from "react"
import { Button } from "../../partial/button/Button"
import { FormRow } from "../../partial/Form"
import { TOPOLOGICA_URL } from "../../app/utils/configs"
import { CollectionResponseDto } from "../../app/service/collection"
import { Toggle } from "../../partial/input/Toggle"

export const CollectionForm = ({
  item,
  onSave,
}: {
  item: CollectionResponseDto
  onSave: (place: CollectionResponseDto) => void
}) => {
  const [collection, setCollection] = useState<CollectionResponseDto>(item)

  const url=TOPOLOGICA_URL+"/collection/"+collection.slug;

  console.log(collection);

  return (
    <>
      <FormRow title="URL">
          <a target="_blank" href={url} className="text-[#0AA2BF]">{url}</a>
      </FormRow>

      <FormRow title="Label">
        <input
          type="text"
          placeholder="Name"
          className="user-edit-item-input w-100"
          value={collection?.label}
          onChange={(e) => setCollection({ ...collection, label: e.target.value })}
        />
      </FormRow>

      <FormRow title="Description">
        <textarea
          placeholder="Description"
          className="user-edit-item-input min-h-[300px]"
          value={collection?.content || ""}
          onChange={(e) => setCollection({ ...collection, content: e.target.value })}
        />
      </FormRow>

      <FormRow title='Active'>
        <Toggle value={collection.active} onChange={(active) => setCollection({ ...collection, active })} />
      </FormRow>

      <div className="user-edit-footer py-2">
        <Button title="Save" onClick={() => onSave(collection)} />
      </div>
    </>
  )
}
