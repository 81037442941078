import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import Papa from "papaparse"

import Alert from "react-s-alert"

import { ImportService, TagDto } from "../../../app/service/import"

import { importConfiguration } from "./../../../app/routing/import.configuration"
import { SplashButton } from "../../../partial/button/SplashButton"
import { Title } from "../../../partial/Title"
import FileLoader from "../../../partial/file/FileLoader"
import { Toggle } from "../../../partial/input/Toggle"

export const ImportModelView = () => {
  const history = useHistory()
  const { model } = useParams<{ model: string }>()
  const [hasHeader, setHasHeader] = useState<boolean>(false)
  const [parsedCsvData, setParsedCsvData] = useState<any>([])

  useEffect(() => {
    fetch()
  }, [])

  const fetch = () => {}

  const submit = () => {
    submitData(parsedCsvData);
  }

  const submitChunk = () => {
    const chunkSize = 50;
    for (let i = 0; i < parsedCsvData.length; i += chunkSize) {
        const chunk = parsedCsvData.slice(i, i + chunkSize);
        submitData(chunk);
    }
  }

  const submitData = (data:Array<any>) => {
    new ImportService().import({
      model: model,
      list: data
    }).then(r=>{
      Alert.info("success");
    }).catch((e)=>{
      Alert.warning("error");
    })
  }

  const uploadCSV = (file: any) => {
    Papa.parse(file, {
      header: hasHeader,
      complete: (results) => {
        delete results.data[0]
        setParsedCsvData(results.data)
      },
    })
  }

  return (
    <div className="p-5">
      <Title
        title={`Upload bulk data`}
        right={
          <span className="flex gap-3">
            <SplashButton
              title="Back"
              icon="arrow-left-circle"
              onClick={() => {
                history.goBack()
              }}
            />
          </span>
        }
      />
      <FileLoader
        onUpload={(formData) => {
          uploadCSV(formData)
        }}
        returnFormData={false}
        title="Click to upload or drag and drop"
        subtitle=""
        className="my-5 mb-10"
        acceptTypes={[".csv"]}
      />

      <Title
        title={`Required CSV structure`}
        className="my-5 items-center"
        right={
          <span className="flex items-center">
            <Toggle
              value={hasHeader}
              onChange={() => {
                setHasHeader(!hasHeader)
              }}
            />{" "}
            <span className="ml-2">CSV has header (0 row)?</span>
          </span>
        }
      />
      {importConfiguration[model].map((field) => (
        <span className="p-2 border mr-3 bg-white">{field}</span>
      ))}

      <div className="my-10" />
      {parsedCsvData.length > 0 && (
        <>
          <Title
            title={`Data preview`}
            className="items-center flex"
            right={
              <div className="flex gap-5">
                <SplashButton
                  title="Submit data to system"
                  icon="database"
                  onClick={() => {
                    submit()
                  }}
                  forceOpen={true}
                />

              <SplashButton
                  title="Submit chunk data to system"
                  icon="database"
                  onClick={() => {
                    submitChunk()
                  }}
                  forceOpen={true}
                />
              </div>
            }
          />

          <div className="border rounded w-auto">
            {parsedCsvData.map((item: Array<string>, key: number) => {
              return (
                <div
                  className="cursor-pointer bg-white text-black p-2 border-t border-b "
                  key={key}
                >
                  {item.map((value) => {
                    const w = 100 / item.length
                    return (
                      <div
                        className={`px-2 inline w-fit`}
                        // style={{ width: w + "%" }}
                      >
                        {value}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}
