import classNames from "classnames"
import React from "react"

const Tag = ({
  tagColor,
  title,
  className,
}: {
  tagColor: string
  title?: string
  className?: string
}) => {
  const classes: Record<string, string> = {
    green: "text-[#027A48] border-[#027A48] bg-[#ECFDF3] border",
    gray: "text-[#757E8C] border-[#757E8C] bg-[#F4F4F4] border",
    blue: "text-[#2B4FF1] border-[#2B4FF1] border",

    purple: "text-[#5925DC] border-[#5925DC] bg-[#F4F3FF] border",
    rose: "text-[#C01048] border-[#C01048] bg-[#FFF1F3] border",
    orange: "text-[#C4320A] border-[#C4320A] bg-[#FFF6ED] border",
  }

  return (
    <span
      className={classNames(
        `tag ${classes[tagColor]} px-2 py-1 mr-1 capitalize text-sm`,
        className
      )}
    >
      {title?.toLowerCase()}
    </span>
  )
}

export default Tag
