import Alert from "react-s-alert"
import classNames from "classnames"

import FileLoader from "./FileLoader"

import MediaService from "../../../app/service/@/media/media.service"
import { UploadApiResponse } from "../../../app/service/@/media/media.dto"

const ImageLoader = ({
  value,
  onChange,
}: {
  value?: string
  onChange?: (data: UploadApiResponse) => void
}) => {
  const onUpload = (formData: FormData) => {
    MediaService.upload(formData)
      .then((r) => {
        Alert.info("Media was successfully uploaded")
        onChange && onChange(r.data)
      })
      .catch(() => {
        Alert.warning("Warning while uploading")
      })
  }

  return (
    <div className="flex w-full">
      {value && (
        <div className="w-[50%] py-2 pr-5 flex items-center  justify-center">
          <img src={value} className="rounded-md max-h-[200px]" />
        </div>
      )}
      <div className={classNames(value ? "w-[50%]" : "w-full")}>
        <FileLoader
          onUpload={(formData) => {
            onUpload(formData)
          }}
          acceptTypes={[
            "image/jpeg",
            "image/gif",
            "image/png",
            "image/svg+xml",
          ]}
          title="Click to upload or drag and drop"
          subtitle="SVG, PNG, JPG or GIF (max. 800x400px)"
        />
      </div>
    </div>
  )
}

export default ImageLoader
