export type NavItemProp = {
  title: string
  link: string
  icon: string
  sub?: Array<{
    title: string
    link: string
    icon?: string
    type?: string
  }>
  path?: string
}

export const adminMenuLinks: Array<NavItemProp> = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: "monitor",
  },
  {
    title: "Places",
    link: "/places",
    icon: "map-pin",
  },
  {
    title: "Countries",
    link: "/countries",
    icon: "flag",
  },
  {
    title: "Tags",
    link: "/tags",
    icon: "tag",
  },
  {
    title: "Collections",
    link: "/collections",
    icon: "list",
  },
  {
    title: "Users",
    link: "/users",
    icon: "users",
  },
  {
    title: "Blogs",
    link: "/blogs",
    icon: "file-text",
  },
  {
    title: "Settings",
    link: "/settings",
    icon: "settings",
  },
]

export const bottomLinks = [
  {
    id: "logout",
    title: "Log Out",
    icon: "log-out",
  },
]
