import TokenService from "../token.service"

import { SigninRequestDto, SigninResponseDto } from "./auth.dto"

import BaseApiService from "./../base.api.service"

export class AuthService {
  isAuthenticated(): boolean {
    return Boolean(TokenService.getLocalAccessToken())
  }

  getAccount(): any {
    return TokenService.getAccount()
  }

  async signin(
    signinRequest: SigninRequestDto,
    handleSuccess: (_data: SigninResponseDto) => void,
    handleError: (err: Error) => void
  ) {
    return BaseApiService.post("/account/signin", signinRequest)
      .then((r) => {
        TokenService.setToken(r.data.jwt)
        TokenService.setAccount(r.data.account)
        TokenService.setLocation(r.data.location)
        handleSuccess(r.data)
      })
      .catch((r) => {
        handleError(r)
      })
  }

  signout(): boolean {
    TokenService.removeToken()
    return true
  }
}

export default new AuthService()
