import classNames from "classnames"
import Alert from "react-s-alert"

// @ts-ignore
import FeatherIcon from "feather-icons-react"

import Dropzone from "react-dropzone"

type FileLoaderProps = {
  onUpload: (formData: FormData) => void
  title: string
  subtitle: string
  className?: string

  acceptTypes?: string[]
  limitFileSize?: number
  returnFormData?: boolean
}

const FileLoader = ({
  onUpload,
  title,
  subtitle,
  className,
  acceptTypes = [],
  limitFileSize = 5000000, // 5 MB,
  returnFormData = true,
}: FileLoaderProps) => {
  const uploadFile = (acceptedFiles: any) => {
    if (!acceptedFiles[0]) {
      Alert.info("fileLoader.fileError")
      return
    }

    if (returnFormData) {
      const formData = new FormData()
      formData.append("file", acceptedFiles[0])
      onUpload(formData)
    } else {
      onUpload(acceptedFiles[0])
    }
  }

  return (
    <span className={classNames("w-full", className)}>
      <Dropzone
        onDrop={(acceptedFiles) => uploadFile(acceptedFiles)}
        accept={acceptTypes.join(",")}
        maxSize={limitFileSize}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="p-10 rounded-xl cursor-pointer bg-white border border-gray-300  my-3 text-center  w-full">
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              <div
                className={
                  "rounded-full p-3 bg-[#F4F4F4] w-[45px] h-[45px] inline-block"
                }
              >
                <FeatherIcon icon="upload-cloud" size="20" />
              </div>

              <div className="my-2 mt-4">{title}</div>
              <div className="my-2 text-xs text-gray-500">{subtitle}</div>
            </div>
          </section>
        )}
      </Dropzone>
    </span>
  )
}

export default FileLoader
