import { ReactNode, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { CountryService, CountryResponseDto } from "../../app/service/country"

import { Title } from "../../partial/Title"
import { Table, TableConfiguration } from "../../components/common/table/Table"
import { SplashButton } from "../../partial/button/SplashButton"

export const Countries = () => {
  const history = useHistory()
  // const [filter, setFilter] = useState<string>("")

  const table: TableConfiguration = {
    data: {
      serviceMethod: new CountryService().list,
      // ...(filter && { serviceMethodPayload: { status: filter } }),
    },

    render: (item: CountryResponseDto): ReactNode => {
      return (
        <Link to={`/countries/${item.slug}`} className="flex w-full">
          <div className="px-3 w-[30%] flex items-center">{item.name}</div>
          <div className="px-3 w-[10%] text-[#757E8C]">{item.countryCode}</div>
          <div className="px-3 w-[20%] text-[#757E8C]">{item.capital}</div>
        </Link>
      )
    },

    title: "Countries",

    isTable: true,

    columns: [
      {
        title: "Name",
        classNames: "w-[30%]",
      },
      {
        title: "Code",
        classNames: "w-[10%]",
      },
      {
        title: "Capital",
        classNames: "w-[20%]",
      },
    ],
  }

  return (
    <div className="p-5">
      <Title
        right={
          <div className="flex gap-5">
            <SplashButton
              title="Bulk upload"
              icon="upload-cloud"
              onClick={() => {
                history.push("/import/countries")
              }}
            />
          </div>
        }
      />

      <Table table={table} />
    </div>
  )
}
