import classNames from "classnames"
import { ReactNode } from "react"

export const Button = ({
  title,
  onClick,
  className,
  enable = true,
}: {
  title: string | ReactNode
  onClick: () => void
  className?: string
  enable?: boolean
}) => {
  return (
    <div
      className={classNames(
        "flex",
        "py-2",
        "px-3",
        "items-center",
        "justify-center",
        "rounded-md",
        "border",
        "border-gray-200",
        "bg-white hover:bg-black",
        "text-black hover:text-white",
        enable && "cursor-pointer",
        enable ? "text-black" : "text-gray-200",
        className
      )}
      onClick={() => enable && onClick()}
    >
      {title}
    </div>
  )
}
