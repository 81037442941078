import React from "react"
import ReactDOM from "react-dom"

import "react-s-alert/dist/s-alert-default.css"
import "./assets/css/index.css"

import App from "./app/App"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
