import { AccountLocationData, AccountResponseDto } from "./account/account.dto"

class TokenService {
  setToken(token: string): void {
    localStorage.setItem("token", token)
  }

  removeToken(): void {
    localStorage.removeItem("token")
  }

  getLocalAccessToken(): string | null {
    const token = localStorage.getItem("token")
    return token
  }

  getAccount(): AccountResponseDto {
    const account = localStorage.getItem("account")
    return account ? JSON.parse(account) : ""
  }

  setAccount(account: any): void {
    localStorage.setItem("account", JSON.stringify(account))
  }

  getLocation(): AccountLocationData {
    const location = localStorage.getItem("location")

    return location
      ? (JSON.parse(location) as AccountLocationData)
      : {
          name: "",
          thumbnail: "",
        }
  }

  setLocation(location: any): void {
    localStorage.setItem("location", location)
  }
}

export default new TokenService()
