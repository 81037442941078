import { AxiosResponse } from "axios"
import BaseApiService from "./@/base.api.service"

import { ListRequestDto, ListResponseDto } from "./dto"

export type AccountResponseDto = {
  id: string
  username:string
  firstName: string
  lastName: string
  email: string
  role: string
  status: string
  cover: string
  createdAt: Date
  updatedAt: Date
}

export type AccountResponseDto2 = {
  id: string;
  slug: string;
  username: string
  role: string;
  email: string;
  firstName?: string;
  lastName?: string;
  cover?: string;
  country?:string
  
  stat_places?: string
  stat_countries?: string
  stat?: string
}

export type UserCUDto = {
  email: string
}

export class UserService {
  async get(id: string): Promise<AxiosResponse<AccountResponseDto>> {
    return BaseApiService.get("/users/get/" + id)
  }

  async list(
    payload?: ListRequestDto
  ): Promise<AxiosResponse<ListResponseDto<AccountResponseDto>>> {
    return BaseApiService.post("/users/list", payload)
  }

  async createOrUpdate(payload: UserCUDto): Promise<AxiosResponse<UserCUDto>> {
    return BaseApiService.patch("/users/createOrUpdate", payload)
  }
}
