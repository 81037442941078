import axios from "axios"
import TokenService from "./token.service"

const URL = process.env.REACT_APP_API_URL

const BaseApiService = axios.create({
  baseURL: URL,
})

// Add a request interceptor
BaseApiService.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken()
    if (token) {
      config.headers["Authorization"] = "Bearer " + token
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default BaseApiService
