export const TOPOLOGICA_URL='https://topologica.co'
export const VERCEL_HOOK='https://api.vercel.com/v1/integrations/deploy/prj_zEPAPl0OBc95O0bAUVIpzqP0ETHk/F6Ea1KZhHr'

export const STATUS_TAGS: Record<string, string> = {
  active: "green",
  inactive: "orange",
  pending: "gray",
}

export const CONTINENTS:Record<string,string>={
  1:'North and Central America',
  2:'South America',
  3:'Europe',
  4:'Middle East',
  5:'Africa',
  6:'Asia',
  7:'Australia and Oceania',
}