import { useState } from "react"

import AccountService from "../../app/service/@/account/account.service"
import { AccountResponseDto } from "../../app/service/@/account/account.dto"
import tokenService from "../../app/service/@/token.service"

import Alert from "react-s-alert"

import { FormRow } from "../../partial/Form"

import Button from "../../components/common/button/Button"
import ImageLoader from "../../components/common/file/ImageLoader"

export const ProfileForm = () => {
  const [account, setAccount] = useState<AccountResponseDto>(
    tokenService.getAccount()
  )

  const onSave = () => {
    AccountService.update(account)
      .then((r) => {
        tokenService.setAccount(r.data)
        Alert.info("Account succesfully updated")
        window.location.reload()
      })
      .catch((e) => {
        console.log(e);
        Alert.warning("Error while updating account")
      })
  }

  return (
    <>
      <FormRow title="Name">
        <div className="flex justify-between gap-5">
          <div className="w-[50%]">
            <input
              type="text"
              placeholder="First Name"
              className="user-edit-item-input w-100"
              value={account?.firstName}
              onChange={(e) =>
                setAccount({ ...account, firstName: e.target.value })
              }
            />
          </div>

          <div className="w-[50%]">
            <input
              type="text"
              placeholder="Last Name"
              className="user-edit-item-input"
              value={account?.lastName}
              onChange={(e) =>
                setAccount({ ...account, lastName: e.target.value })
              }
            />
          </div>
        </div>
      </FormRow>
      <FormRow title="Email">
        <input
          type="text"
          placeholder="Email"
          className="user-edit-item-input"
          value={account?.email}
          onChange={(e) => setAccount({ ...account, email: e.target.value })}
        />
      </FormRow>
      <FormRow
        title="Your photo"
        subtitle="This will be displayed on your profile."
      >
        <div className="flex">
          <ImageLoader
            value={account.cover}
            onChange={(file) =>
              setAccount({ ...account, cover: file.secure_url })
            }
          />
        </div>
      </FormRow>

      <div className="user-edit-footer py-2">
        <Button title="Save" onClick={() => onSave()} />
      </div>
    </>
  )
}
