import { AxiosResponse } from "axios"

import BaseApiService from "../base.api.service"
import { UploadApiResponse } from "./media.dto"

class MediaService {
  async upload(formData: any, nid?:string): Promise<AxiosResponse<UploadApiResponse>> {
    return BaseApiService.post(("/media/upload/"+(nid?nid:'')), formData)
  }
}

export default new MediaService()
