import { useState } from "react"
import Alert from "react-s-alert"

import {
  AccountPasswordUpdateDto,
  AccountResponseDto,
} from "../../app/service/@/account/account.dto"
import AccountService from "../../app/service/@/account/account.service"

import { FormRow } from "../../partial/Form"
import Button from "../../components/common/button/Button"

export const SecurityForm = () => {
  const [payload, setPayload] = useState<AccountPasswordUpdateDto>({
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  })

  const onSave = () => {
    if (
      !payload.currentPassword ||
      !payload.newPassword ||
      !payload.newPasswordConfirm
    ) {
      Alert.warning("Password can not be empty")
      return
    }

    if (payload.newPassword !== payload.newPasswordConfirm) {
      Alert.warning("New passwords does mismatch")
      return
    }

    AccountService.passwordUpdate(payload)
      .then((r) => {
        Alert.info("Password was succesfully updated")
      })
      .catch((e) => {
        Alert.warning("Error while password updating")
      })
  }

  return (
    <>
      <FormRow title="Security" subtitle="Update password" noBorder>
        <div className="my-3">
          <input
            type="password"
            placeholder="Current password"
            className="user-edit-item-input"
            value={payload.currentPassword}
            onChange={(e) =>
              setPayload({ ...payload, currentPassword: e.target.value })
            }
          />
        </div>

        <div className="my-3">
          <input
            type="password"
            placeholder="New password"
            className="user-edit-item-input"
            value={payload.newPassword}
            onChange={(e) =>
              setPayload({ ...payload, newPassword: e.target.value })
            }
          />
        </div>

        <div className="my-3">
          <input
            type="password"
            placeholder="Confirm new password"
            className="user-edit-item-input"
            value={payload.newPasswordConfirm}
            onChange={(e) =>
              setPayload({ ...payload, newPasswordConfirm: e.target.value })
            }
          />
        </div>
      </FormRow>

      <div className="flex justify-end my-3">
        <Button
          title="Save password changes"
          onClick={() => {
            onSave()
          }}
        />
      </div>
    </>
  )
}
