// @ts-ignore
import FeatherIcon from "feather-icons-react"
import classNames from "classnames"

export const PageNotFound = () => {
  return (
    <div className="w-100 h-screen flex items-center justify-center">
      <div className="flex flex-col justify-center items-center bg-white rounded-xl border border-gray-200 p-5">
        <div
          className={classNames(
            "bg-[#EFF8FF] w-[150px] h-[150px] rounded-full mr-3 flex justify-center items-center",
            "text-[#2B4FF1]"
          )}
        >
          {<FeatherIcon icon="alert-triangle" size="90" />}
        </div>
        <div className="flex flex-col justify-center items-center my-3">
          <h1 className="text-3xl font-extrabold my-3">Not found!</h1>
          <div className="text-md font-extrabold">
            The page you’re trying to access does not exist.
          </div>
        </div>
      </div>
    </div>
  )
}
