// @ts-ignore
import FeatherIcon from "feather-icons-react"

import classNames from "classnames"

export type GridItem = {
  title: string
  number: number
  icon: { name: string; color: string; bg: string }
}

export const DashboardScore = ({ item }: { item: GridItem }) => {
  return (
    <div className="flex p-6 bg-white rounded-md border border-gray-200">
      <div
        className={classNames(
          item.icon.bg,
          "flex justify-center rounded-full w-11 h-11 mr-3 items-center",
          item.icon.color
        )}
      >
        {<FeatherIcon icon={item.icon.name} size="20" />}
      </div>
      <div>
        <div className="text-xl font-bold">{item.number}</div>
        <div className="text-sm">{item.title}</div>
      </div>
    </div>
  )
}
