import { Title } from "./../../partial/Title"
import { FormHeader } from "./../../partial/Form"

import { ProfileForm } from "./ProfileForm"
import { SecurityForm } from "./SecurityForm"
import { LocationForm } from "./LocationForm"

export const Settings = () => {
  return (
    <div className="p-5">
      <Title title="Settings" />
      <FormHeader title="Profile Settings">
        <ProfileForm />
        <hr className="my-10" />
        <SecurityForm />
      </FormHeader>
      {/* <div className="my-5" />
      <FormHeader title="Manage Workplace">{<LocationForm />}</FormHeader> */}
    </div>
  )
}
