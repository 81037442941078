import { useState } from "react"
import { Button } from "../../partial/button/Button"
import { FormRow } from "../../partial/Form"
import { TOPOLOGICA_URL } from "../../app/utils/configs"
import { CountryResponseDto } from "../../app/service/country"

export const CountryForm = ({
  item,
  onSave,
}: {
  item: CountryResponseDto
  onSave: (country: CountryResponseDto) => void
}) => {
  const [country, setCountry] = useState<CountryResponseDto>(item)

  const url=TOPOLOGICA_URL+"/country/"+country.slug;

  return (
    <>
      <FormRow title="URL">
          <a target="_blank" href={url} className="text-[#0AA2BF]">{url}</a>
      </FormRow>

      <FormRow title="Name">
        <input
          type="text"
          placeholder="Name"
          className="user-edit-item-input w-100"
          value={country?.name}
          onChange={(e) => setCountry({ ...country, name: e.target.value })}
        />
      </FormRow>

      <FormRow title="Country code / Capital">
        <div className="flex justify-between gap-5">
          <div className="w-[50%]">
            <input
              type="text"
              placeholder="Country code"
              className="user-edit-item-input"
              value={country?.countryCode || ""}
              onChange={(e) => setCountry({ ...country, countryCode: e.target.value })}
            />
          </div>
          <div className="w-[50%]">
            <input
              type="text"
              placeholder="Capital"
              className="user-edit-item-input"
              value={country?.capital || ""}
              onChange={(e) => setCountry({ ...country, capital: e.target.value })}
            />
          </div>
        </div>
      </FormRow>

      <FormRow title="Center coordinates (lat, lng)">
        <div className="flex justify-between gap-5">
          <div className="w-[50%]">
            <input
              type="text"
              placeholder="Latitude"
              className="user-edit-item-input"
              value={country?.lat || ""}
              onChange={(e) => setCountry({ ...country, lat: e.target.value })}
            />
          </div>
          <div className="w-[50%]">
            <input
              type="text"
              placeholder="Longitude"
              className="user-edit-item-input"
              value={country?.lng || ""}
              onChange={(e) => setCountry({ ...country, lng: e.target.value })}
            />
          </div>
        </div>
      </FormRow>

      <FormRow title="Travel Info">
        <textarea
          placeholder="Travel Info"
          className="user-edit-item-input min-h-[300px]"
          value={country?.travelInfo || ""}
          onChange={(e) => setCountry({ ...country, travelInfo: e.target.value })}
        />
      </FormRow>

      <FormRow title="Overview">
        <textarea
          placeholder="Overview"
          className="user-edit-item-input min-h-[300px]"
          value={country?.overview || ""}
          onChange={(e) => setCountry({ ...country, overview: e.target.value })}
        />
      </FormRow>

      <FormRow title="Statistics">
        <textarea
          placeholder="Statistics"
          className="user-edit-item-input min-h-[300px]"
          value={country?.statistics || ""}
          onChange={(e) => setCountry({ ...country, statistics: e.target.value })}
        />
      </FormRow>

      <div className="user-edit-footer py-2">
        <Button title="Save" onClick={() => onSave(country)} />
      </div>
    </>
  )
}
