import { ReactNode } from "react"
import classNames from "classnames"

const Card = ({
  children,
  className,
  title,
  style = "default",
}: {
  title?: string
  className?: string
  children: ReactNode
  style?: "default" | "big"
}) => {
  return (
    <div
      className={classNames(
        "bg-white",
        "p-3",
        "border",
        "rounded-md",
        "border-gray-200",
        className
      )}
    >
      {title && (
        <div
          className={classNames(
            style == "default"
              ? "text-md font-bold border-b pb-3 border-[#DDDDDD]"
              : "text-2xl font-bold pb-4"
          )}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  )
}

export default Card
