import React from "react"
import { FieldValues, useController, UseControllerProps } from "react-hook-form"

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export type TextInputProps<T extends FieldValues = FieldValues> = InputProps &
  UseControllerProps<T> & {
    label?: string
    error?: string
  }

function TextInput<T>(props: TextInputProps<T>) {
  const { field, fieldState } = useController(props)

  return (
    <>
      <input
        {...props}
        {...field}
        className={`${props.className} ${
          fieldState.invalid ? "is-invalid" : ""
        }`}
      />
      {fieldState.invalid && props.error && (
        <div className="text-center text-[red]">{props.error}</div>
      )}
    </>
  )
}

export default TextInput
