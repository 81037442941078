import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { CollectionForm } from "./CollectionForm"
import { dateFormat } from "../../app/utils/index"
import { SplashButton } from "../../partial/button/SplashButton"
import { FormHeader } from "../../partial/Form"
import { ProfileTop } from "../../partial/ProfileTop"
import { CollectionResponseDto, CollectionService } from "../../app/service/collection"


export const CollectionView = () => {
  const history = useHistory()
  const [collection, setCollection] = useState<CollectionResponseDto>()
  const { id } = useParams<{id: string}>()

  useEffect(() => {
    fetch()
  }, [])

  const fetch = () => {
    new CollectionService().get(id).then((r) => {
      setCollection(r.data)
    })
  }

  const onSave = (collection: CollectionResponseDto) => {}

  return (
      <div className="p-5">
        {collection && (
          <>
            <ProfileTop
              profile={{ name: collection.label, title: "Created at: " + dateFormat(collection.createdAt) + ", Updated at: " + dateFormat(collection.updatedAt) }}
              right={ <span className="flex gap-3"> <SplashButton title="Back" icon="arrow-left-circle" onClick={() => { history.goBack() }} /> </span> }
            />

            <div className="my-5">
              <FormHeader title="Collection Settings">
                <CollectionForm item={collection} onSave={(collection) => onSave(collection)} />
              </FormHeader>
            </div>
         </>
        )}
      </div>
  )
}
