export const importConfiguration: Record<string, Array<string>> = {
  tags: ["Name"],
  collections: ["Name"],
  countries: ["Code", "Country/Territory name", "Continent", "Belong to Country", "Geo", "CODE", "Overview", "Travel info", "Statistics", "Capital"],
  places: [
    "C Id",
    "Code",
    "Name",
    "Country name",
    "Type",
    "Continent",
    "Country code",
    "Coordinates",
    "Hashtags",
    "Collection"
  ],
  places_descriptions: [
    "C Id",
    "Code",
    "Descriptions",
  ],
}
