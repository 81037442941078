import React, { useState } from "react"
import classNames from "classnames"

// @ts-ignore
import FeatherIcon from "feather-icons-react"

export const SplashButton = ({
  title,
  icon,
  onClick,
  className,
  forceOpen = false,
}: {
  title: string
  icon: string
  onClick?: () => void
  className?: string
  forceOpen?: boolean
}) => {
  const [open, setOpen] = useState<boolean>(forceOpen)

  return (
    <div className="flex">
      <div
        onMouseEnter={() => {
          !forceOpen && setOpen(true)
        }}
        onMouseLeave={() => {
          !forceOpen && setOpen(false)
        }}
        className={classNames(
          "flex h-[42px] p-2 items-center justify-center border border-gray-200 cursor-pointer rounded-full",
          open
            ? ["bg-black", "text-white", "rounded-full", "px-3"]
            : ["bg-white", "w-[42px]"],
          className
        )}
        onClick={() => onClick && onClick()}
      >
        <FeatherIcon icon={icon} size="18" />
        {open && <span className="ml-2">{title}</span>}
      </div>
    </div>
  )
}
