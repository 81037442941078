import React from "react"
import { Sidebar } from "./Sidebar"

const LayoutPrivate: React.FC = (props: { children?: React.ReactNode }) => {
  return (
    <div className="flex w-full">
      <div className="flex flex-col w-[270px] min-h-[100vh] justify-between fixed bg-white px-4 py-6">
        <Sidebar />
      </div>
      <div className="ml-[270px] w-full min-h-[100vh] px-6 py-8">
        {props.children}
      </div>
    </div>
  )
}

export default LayoutPrivate
