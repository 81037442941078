import { ReactNode, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { PlaceService, PlaceResponseDto } from "../../app/service/place"

import { Title } from "../../partial/Title"

import { Table, TableConfiguration } from "../../components/common/table/Table"
import { SplashButton } from "../../partial/button/SplashButton"
import { CONTINENTS } from "../../app/utils/configs"

export const Places = () => {
  const history = useHistory()
  // const [filter, setFilter] = useState<string>("")

  const table: TableConfiguration = {
    data: {
      serviceMethod: new PlaceService().list,
      serviceMethodPayload: {withNoImages:true}
      // ...(filter && { serviceMethodPayload: { status: filter } }),
    },

    render: (item: PlaceResponseDto): ReactNode => {
      return (
        <Link to={`/places/${item.slug}`} className="flex w-full">
          <div className="px-3 w-[25%] flex items-center">{item.name}</div>
          <div className="px-3 w-[25%]">{item.countryName}</div>
          <div className="px-3 w-[20%]">{item.continent&&CONTINENTS[item.continent]}</div>
          <div className="px-3 w-[20%]">
            {item.lat && item.lng && `${item.lat}, ${item.lng}`}
          </div>
        </Link>
      )
    },

    title: "Places",

    isTable: true,

    columns: [
      {
        title: "Name",
        classNames: "w-[25%]",
      },
      {
        title: "Country",
        classNames: "w-[25%]",
      },
      {
        title: "Continent",
        classNames: "w-[20%]",
      },
      {
        title: "Coordinates (lat, lng)",
        classNames: "w-[20%]",
      },
    ],
  }

  return (
    <div className="p-5">
      <Title
        right={
          <div className="flex gap-5">
            <SplashButton
              title="Add place"
              icon="plus-circle"
              onClick={() => {
                history.push("/places/new")
              }}
            />
            <SplashButton
              title="Bulk upload"
              icon="upload-cloud"
              onClick={() => {
                history.push("/import/places")
              }}
            />

          <SplashButton
              title="Bulk description upload"
              icon="upload-cloud"
              onClick={() => {
                history.push("/import/places_descriptions")
              }}
            />


          </div>
        }
      />

      <Table table={table} />
    </div>
  )
}
