import { AxiosResponse } from "axios"
import BaseApiService from "./@/base.api.service"
import { CollectionResponseDto } from "./collection"

import { ListRequestDto, ListResponseDto } from "./dto"
import { AccountResponseDto2 } from "./user"

export type PlaceResponseDto = {
  id: string
  nid: string;
  slug: string | null
  name: string

  description: string | null
  countryName: string | null
  countryCode: string | null

  lat: string | null
  lng: string | null

  type: string | null
  continent: string | null
  image : string | null

  statVisited: number

  createdAt: Date
  updatedAt: Date
  
  tagsLabel:string

  images:Array<PlaceImage>
  visits:Array<PlaceVisit>
  collections:Array<PlaceCollection>
}

export type PlaceVisit={
  type: string
  id: string
  account: AccountResponseDto2
}

export type PlaceImage = {
  id: string
  placeId: string
  url: string
  countryCode: string
  prior?: number
  hideOnCountry?: boolean
}

export type PlaceCollection={
  collection: CollectionResponseDto
}

export type PinData={
  placeId: string
  action: 'PIN' | 'UNPIN'
  type: 'V' | 'P'
}

export type PinResponse={
  visited: Array<string>
  planned: Array<string>
}

export type PlaceCUDto = {
  id: string;
  name: string;
  description: string | null
  lat: string | null
  lng: string | null
  statVisited: number
}


export class PlaceService {

  async get(id: string): Promise<AxiosResponse<PlaceResponseDto>> {
    return BaseApiService.get("/places/get/" + id)
  }

  async list(payload: ListRequestDto): Promise<AxiosResponse<ListResponseDto<PlaceResponseDto>>> {
    return BaseApiService.post("/places/list", payload)
  }

  async createOrUpdate(payload: PlaceCUDto): Promise<AxiosResponse<PlaceResponseDto>> {
    return BaseApiService.post("/places/createOrUpdate/", payload)
  }

  async delete(id: string): Promise<AxiosResponse<PlaceResponseDto>> {
    return BaseApiService.delete("/places/delete/" + id)
  }

  async updateImages(id: string, images: PlaceImage[]): Promise<AxiosResponse<PlaceImage[]>> {
    return BaseApiService.post("/places/imagesUpdate/" + id, images)
  }

  
}
