import { ReactNode, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { TagService, TagResponseDto } from "../../app/service/tag"

import { Title } from "../../partial/Title"
import { Table, TableConfiguration } from "../../components/common/table/Table"
import { SplashButton } from "../../partial/button/SplashButton"

export const Tags = () => {
  const history = useHistory()
  // const [filter, setFilter] = useState<string>("")

  const table: TableConfiguration = {
    data: {
      serviceMethod: new TagService().list,
      serviceMethodPayload: { all: true }
    },

    render: (item: TagResponseDto): ReactNode => {
      return (
        <Link to={`/tags/${item.slug}`} className="flex w-full">
          <div className="px-3 w-[70%] flex items-center">{item.label}</div>
          <div className="px-3 w-[30%] flex items-center">{item.active?'ACTIVE':'NOT ACTIVE'}</div>
        </Link>
      )
    },

    title: "Tags",

    isTable: true,

    columns: [
      {
        title: "Name",
        classNames: "w-[70%]",
      },
      {
        title: "State",
        classNames: "w-[30%]",
      },
    ],
  }

  return (
    <div className="p-5">
      <Title
        right={
          <div className="flex gap-5">
            <SplashButton
              title="Bulk upload"
              icon="upload-cloud"
              onClick={() => {
                history.push("/import/tags")
              }}
            />
          </div>
        }
      />

      <Table table={table} />
    </div>
  )
}
