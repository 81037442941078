import  { useState,useEffect } from "react"
import Alert from "react-s-alert"

import { useAuth } from "../../app/providers/auth.provider"

import { DashboardStats } from "../../app/service/dto"
import { DataService } from "../../app/service/data"

import { Title } from "../../partial/Title"
import { DashboardScore } from "../../partial/DashboardScore"

import { Button } from "../../partial/button/Button"
import axios from "axios"
import { VERCEL_HOOK } from "../../app/utils/configs"


export const Dashboard = () => {
  const { account } = useAuth()

  const [dashboardData, setDashboardData]=useState<DashboardStats>({
    users: 0,
    places: 0,
    tags: 0,
    collections: 0,
    countries: 0,
    images: 0,
    visits: 0
  })

  useEffect(() => {
    fetch()
  }, [])

  const fetch = () => {
    new DataService().dashboard().then((r) => {
      setDashboardData(r.data)
    }).catch(r=>console.log(r))
  }

  return (
    <div className="p-5">
      <Title title={`Welcome, ${account?.firstName}!`} />

      <div className="flex grid grid-cols-4 gap-10">
        <DashboardScore
          item={{
            title: "Users",
            number: dashboardData.users,
            icon: {
              name: "users",
              color: "text-[#00C98D]",
              bg: "bg-[#ECFDF3]",
            },
          }}
        />

        <DashboardScore
          item={{
            title: "Places",
            number: dashboardData.places,
            icon: {
              name: "map-pin",
              color: "text-[#00C98D]",
              bg: "bg-[#ECFDF3]",
            },
          }}
        />

        <DashboardScore
          item={{
            title: "Tags",
            number: dashboardData.tags,
            icon: {
              name: "tag",
              color: "text-[#00C98D]",
              bg: "bg-[#ECFDF3]",
            },
          }}
        />

        <DashboardScore
          item={{
            title: "Collections",
            number: dashboardData.collections,
            icon: {
              name: "list",
              color: "text-[#00C98D]",
              bg: "bg-[#ECFDF3]",
            },
          }}
        />

        <DashboardScore
          item={{
            title: "Countries / Territorries",
            number: dashboardData.countries,
            icon: {
              name: "flag",
              color: "text-[#00C98D]",
              bg: "bg-[#ECFDF3]",
            },
          }}
        />

        <DashboardScore
          item={{
            title: "Images",
            number: dashboardData.images,
            icon: {
              name: "image",
              color: "text-[#00C98D]",
              bg: "bg-[#ECFDF3]",
            },
          }}
        />

        <DashboardScore
          item={{
            title: "Visits",
            number: dashboardData.visits,
            icon: {
              name: "map-pin",
              color: "text-[#00C98D]",
              bg: "bg-[#ECFDF3]",
            },
          }}
        />
      </div>

      <hr className="my-10"/>

      <div className="flex grid grid-cols-4 gap-10">
         <Button title="Генерувати URL" onClick={()=>{
            new DataService().slugs().then(r=>{
              Alert.info("Success")
            });
         }} />

        <Button title={<div>Перегенерувати проект <i className="text-[red]">(обережно)</i></div>} onClick={()=>{
            axios.get(VERCEL_HOOK).then(r=>{
              alert(JSON.stringify(r.data));
            });
         }} />
         
      </div>

    </div>
  )
}
