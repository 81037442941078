import { AxiosResponse } from "axios"
import BaseApiService from "./@/base.api.service"

import { ListRequestDto, ListResponseDto } from "./dto"

export type CollectionResponseDto = {
  id: string
  slug: string | null
  label: string
  content: string | null
  active: boolean
  createdAt: Date
  updatedAt: Date
}

export type CollectionCUDto = {
  label: string
}

export class CollectionService {
  async get(id: string): Promise<AxiosResponse<CollectionResponseDto>> {
    return BaseApiService.get("/collections/get/" + id)
  }

  async list(payload?: ListRequestDto): Promise<AxiosResponse<ListResponseDto<CollectionResponseDto>>> {
    return BaseApiService.post("/collections/list", payload)
  }

  async create(payload: CollectionCUDto): Promise<AxiosResponse<CollectionResponseDto>> {
    return BaseApiService.post("/collections/create/", payload)
  }

  async delete(id: string): Promise<AxiosResponse<void>> {
    return BaseApiService.delete("/collections/delete/" + id)
  }
}
