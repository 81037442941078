import Alert from "react-s-alert"
import { HashRouter as Router, Switch } from "react-router-dom"
import {
  useGetRouteConfiguration,
  IRouteConfiguration,
} from "./routing/routing.configuration"
import { ProvideAuth } from "./providers/auth.provider"

declare global {
  interface Window {
    Atlas: any
  }
}

function App() {
  return (
    <ProvideAuth>
      <Alert stack={{ limit: 3 }} position="bottom-right" />
      <Router>
        <Switch>
          {useGetRouteConfiguration().map(
            (route: IRouteConfiguration, key: number) => {
              return <route.type {...route} key={key} />
            }
          )}
        </Switch>
      </Router>
    </ProvideAuth>
  )
}

export default App
