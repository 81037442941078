import { ReactNode } from "react"
import claassNames from "classnames"

export const Title = ({
  title,
  className,
  left,
  right,
}: {
  title?: string
  className?: string
  right?: ReactNode
  left?: ReactNode
}) => {
  return (
    <div className="flex justify-between">
      <div className={claassNames("font-extrabold text-2xl mb-10", className)}>
        {title}
      </div>
      <span>{right}</span>
    </div>
  )
}
