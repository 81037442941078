import { useState } from "react"
import { PlaceImage,  PlaceResponseDto, PlaceService } from "./../../app/service/place"
import { Button } from "../../partial/button/Button"
import { FormRow } from "../../partial/Form"
import { ImageLoader } from "../../partial/file/ImageLoader"
import { TOPOLOGICA_URL } from "../../app/utils/configs"
import Alert from "react-s-alert"
// @ts-ignore
import FeatherIcon from "feather-icons-react"

export const PlaceForm = ({
  item,
  onSave,
}: {
  item: PlaceResponseDto
  onSave: (place: PlaceResponseDto) => void
}) => {
  const [place, setPlace] = useState<PlaceResponseDto>(item)
  const [images, setImages] = useState<PlaceImage[]>(item.images);
  const url = TOPOLOGICA_URL+"/place/"+place.slug;

  const onSaveImages=()=>{
      new PlaceService().updateImages(place.nid, images).then((r) => {
        // setImages(r.data)
        console.log("r.data", r.data);
        Alert.info("Images was succesfully updated")
      }).catch(e=>{
        Alert.warning("Error while updating images")
      })

  }

  const onImageChange=(id:number, key: string, value: string | number | boolean)=>{
    let newArr = [...images];
    
    const image:PlaceImage=images[id];

    if (key==='url'){
      image.url=value.toString();
    }

    if (key==='prior'){
      image.prior=Number(value);
    }

    if (key==='hideOnCountry'){
      image.hideOnCountry=Boolean(value);
    }

    newArr[id]=image;

    setImages(newArr);
  }

  const onImageDelete=(idx: number)=>{
    const temp = [...images];
    temp.splice(idx, 1);
    setImages(temp);
  }

  return (
    <>
      <FormRow title="URL"> <a target="_blank" href={url} className="text-[#0AA2BF]">{url}</a> </FormRow>
      <FormRow title="Internal ID"> {place.nid} </FormRow>
      <FormRow title="Name">
        <input
          type="text"
          placeholder="Name"
          className="user-edit-item-input w-100"
          value={place?.name}
          onChange={(e) => setPlace({ ...place, name: e.target.value })}
        />
      </FormRow>
      <FormRow title="Description">
        <textarea
          placeholder="Description"
          className="user-edit-item-input min-h-[300px]"
          value={place?.description || ""}
          onChange={(e) => setPlace({ ...place, description: e.target.value })}
        />
      </FormRow>
      <FormRow title="Visited ranking">
        <input
          type="text"
          placeholder="Visited ranking"
          className="user-edit-item-input w-100"
          value={place?.statVisited}
          onChange={(e) => setPlace({ ...place, statVisited: Number(e.target.value) })}
        />
      </FormRow>
      <FormRow title="Coordinates (lat, lng)">
        <div className="flex justify-between gap-5">
          <div className="w-[50%]">
            <input
              type="text"
              placeholder="Latitude"
              className="user-edit-item-input"
              value={place?.lat || ""}
              onChange={(e) => setPlace({ ...place, lat: e.target.value })}
            />
          </div>
          <div className="w-[50%]">
            <input
              type="text"
              placeholder="Longitude"
              className="user-edit-item-input"
              value={place?.lng || ""}
              onChange={(e) => setPlace({ ...place, lng: e.target.value })}
            />
          </div>
        </div>
      </FormRow>
      <FormRow title="Country">
        <input
          disabled
          type="text"
          placeholder="Country"
          className="user-edit-item-input"
          value={place?.countryCode || ""}
          onChange={(e) => setPlace({ ...place, countryCode: e.target.value })}
        />
      </FormRow>
      <FormRow title="Continent">
        <input
          disabled
          type="text"
          placeholder="Continent"
          className="user-edit-item-input"
          value={place?.continent || ""}
          onChange={(e) => setPlace({ ...place, continent: e.target.value })}
        />
      </FormRow>

      <FormRow title="Tags">
        <div className="flex gap-1 text-[#797979]">
          {place.tagsLabel?.split(',').map(tag=>(tag&&<a target="_blank" href={TOPOLOGICA_URL+"/tag/"+tag} key={tag} className="text-[#0AA2BF]">#{tag}</a>))}
        </div>
      </FormRow>
      
      <FormRow title="Collections">
        <div className="flex gap-1 text-[#797979]">
          {place.collections.map(i=>{
             return <a href={TOPOLOGICA_URL+'/collection/'+i.collection.slug} target="_blank" key={i.collection.slug} className="text-[#0AA2BF]">{i.collection.label}</a>
          })}
        </div>
      </FormRow>

      <div className="user-edit-footer py-2">
        <Button title="Save place" onClick={() => onSave(place)} />
      </div>

       <FormRow title="Photos" subtitle="Place gallery">
          {images.map((image, id)=><ImageBlock image={image} key={id}
          nid={place.nid}
          onChange={(key: string, value: string|number|boolean)=>{
            onImageChange(id, key, value);
          }} 
          onDelete={()=>{
            onImageDelete(id);
          }}
          
          />)}

          <Button title="Add" onClick={() => {
            setImages([...images, { id:'', placeId: place.nid, countryCode: place.countryCode || '', url: '', prior: 0, hideOnCountry: false}])
          }} className="mx-10 my-3" />
      </FormRow>

      <div className="user-edit-footer py-2">
          <Button title="Save images" onClick={() => onSaveImages()} />
      </div>

    </>
  )
}

export const ImageBlock=({image, nid, onChange, onDelete} : {image: PlaceImage, nid: string, onChange:(key:string, value: string | boolean)=>void, onDelete:()=>void})=>{
  return  <div className="flex flex-col">
                
                <ImageLoader 
                   value={image.url || ''}
                   nid={nid} 
                   onChange={ (file) => {
                      onChange('url', file.secure_url);
                   }}
                 />

                <div className="flex px-10 items-center gap-6">
                   
                    <span className="flex items-center">Prior <input type="text" placeholder="Sort" className="user-edit-item-input mx-2" value={image.prior}
                      onChange={(e) => {
                        onChange('prior', e.target.value);
                      }} /> </span>

                    <span className="flex items-center">Hide on country <input type="checkbox" className="mx-2" checked={image.hideOnCountry}
                      onChange={(e) => {
                        onChange('hideOnCountry', !image.hideOnCountry);
                      }}
                     /> </span>

                     <span className="text-[#f533f]" onClick={()=>{onDelete()}}>
                      <FeatherIcon icon={"x-circle"} size="18" className="cursor-pointer text-red-500" />
                     </span>
                </div>
          </div>
}
