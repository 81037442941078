import { Redirect, Route } from "react-router-dom"

import { useAuth } from "./../providers/auth.provider"

import LayoutPrivate from "../../components/layout/LayoutPrivate"
import LayoutPublic from "../../components/layout/LayoutPublic"

export interface IRouteConfiguration {
  type: typeof PrivateRoute | typeof PublicRoute
  component: React.FC<any>
  path: string
  exact?: boolean
}

export const PublicRoute = ({
  component: Component,
  exact,
  path,
}: IRouteConfiguration) => {
  const { isLoggedIn } = useAuth()

  // Default redirection from public routes to panel
  if (isLoggedIn && ["/", "/signin"].includes(path)) {
    return <Redirect to="/dashboard" />
  }

  return (
    <Route
      exact={exact}
      key={path}
      path={path}
      render={(props) => (
        <LayoutPublic>
          <Component {...props} />
        </LayoutPublic>
      )}
    />
  )
}

export const PrivateRoute = ({
  component: Component,
  exact,
  path,
}: IRouteConfiguration) => {
  const { isLoggedIn } = useAuth()

  if (!isLoggedIn) {
    return <Redirect to="/signin" />
  }

  if (isLoggedIn && ["/", "/*", "/signin"].includes(path)) {
    return <Redirect to="/dashboard" />
  }

  return (
    <Route
      exact={exact}
      key={path}
      path={path}
      render={(props) => (
        <LayoutPrivate>
          <Component {...props} />
        </LayoutPrivate>
      )}
    />
  )
}
