import { NavLink, useLocation } from "react-router-dom"
import { useAuth } from "../../app/providers/auth.provider"

// @ts-ignore
import FeatherIcon from "feather-icons-react"

import UserAvatar from "../common/primitive/UserAvatar"
import defaultLogo from "./../../assets/image/logo.svg"

import {
  adminMenuLinks,
  bottomLinks,
  NavItemProp,
} from "../../app/routing/sidebar.configuration"

import tokenService from "../../app/service/@/token.service"

export const Sidebar = () => {
  const location = useLocation()
  const menuLinks = adminMenuLinks
  const path = "/" + location.pathname.split("/")[1]

  return (
    <>
      <div className="sidebar-topContent">
        <SidebarTop />
        <div className="sidebar-links">
          {menuLinks.map((item: NavItemProp, j) => {
            return <NavTab {...item} key={"navItem_" + j} path={path} />
          })}
        </div>
      </div>
      <SidebarBottonLinks />
      <SidebarProfile />
    </>
  )
}

const SidebarTop = () => {
  const location = tokenService.getLocation()

  return (
    <div className="sidebar-logo2 flex border-b border-gray-200 mb-3">
      <NavLink
        to={"/"}
        className="flex justify-center w-full items-center mb-3"
      >
        <img
          src={defaultLogo}
          alt={location?.name}
          className=""
          width="80"
          height="50"
        />
        {/* <span className="font-bold px-2 text-bold">Pin 2 Fun</span> */}
      </NavLink>
    </div>
  )
}

const SidebarProfile = () => {
  const { account } = useAuth()

  return (
    <div className="bg-[#F4F4F4] rounded-md flex p-3 items-center">
      <UserAvatar
        url={account?.cover}
        name={account?.firstName + " " + account?.lastName}
        className="mr-3 w-[50px] rounded-full"
        size={37}
      />
      <div className="flex flex-col">
        <span className="font-bold text-md">
          {account?.firstName} {account?.lastName}
        </span>
        <span className="text-sm">{account?.email}</span>
      </div>
    </div>
  )
}

const SidebarLink = ({
  title,
  onClick,
  icon,
}: {
  title: string
  onClick: any
  icon: string
}) => {
  return (
    <div className="sideBar-link" onClick={() => onClick && onClick()}>
      <FeatherIcon icon={icon} size="20" color="#757e8c" className="mr-2" />
      <span className="sideBar-link_title">{title}</span>
    </div>
  )
}

const NavTab = ({ title, link, icon, sub, path }: NavItemProp) => {
  const showSub =
    path && sub && [...sub.map((i) => i.link), link].includes(path)

  return (
    <div className="flex flex-col">
      <NavLink
        className="navLink flex"
        activeClassName="navLink--active"
        to={link}
      >
        <FeatherIcon icon={icon} size="20" color="#757e8c" className="mr-2" />
        <span className="navLink_title">{title}</span>
      </NavLink>
      {sub && showSub && (
        <div className="border-l border-gray-200 pl-3 ml-10">
          {sub.map((subitem, key) =>
            subitem.type && subitem.type === "external" ? (
              <a
                className="p-3 text-[#757e8c] flex hover:bg-[#EFF8FF] rounded-md"
                href={subitem.link}
              >
                {subitem.title}
              </a>
            ) : (
              <NavLink
                className="p-3 text-[#757e8c] flex hover:bg-[#EFF8FF] rounded-md"
                to={subitem.link}
                key={key}
              >
                <FeatherIcon
                  icon={subitem.icon}
                  size="20"
                  color="#757e8c"
                  className="mr-2"
                />
                {subitem.title}
              </NavLink>
            )
          )}
        </div>
      )}
    </div>
  )
}

const SidebarBottonLinks = () => {
  const { signout } = useAuth()

  return (
    <div className="sidebar-links">
      {bottomLinks.map((item, j: number) => {
        return (
          <SidebarLink
            {...item}
            onClick={() => {
              if (item.id === "logout") {
                signout()
              }
            }}
            key={"navItem_" + j}
          />
        )
      })}
    </div>
  )
}
