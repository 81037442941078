// @ts-ignore
import FeatherIcon from "feather-icons-react"
import { ChangeEvent } from "react"

const SearchInput = ({
  value,
  onChange,
}: {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <div className="">
      <label className="relative block text-gray-500">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          {<FeatherIcon icon="search" size="15" />}
        </span>
        <input
          className="w-full bg-white placeholder:font-italic border border-slate-300 rounded-md py-1 pl-7 pr-4 focus:outline-none"
          placeholder="Search"
          type="text"
          value={value}
          onChange={(e) => onChange(e)}
        />
      </label>
    </div>
  )
}

export default SearchInput
