// GENERAL
import { Signin } from "../../pages/_general/Signin"
import { PageNotFound } from "../../pages/_general/PageNotFound"

import { ImportModelView } from "../../pages/@/import/ImportModelView"

import { Dashboard } from "../../pages/dashboard/Dashboard"

import { Places } from "../../pages/places/Places"
import { PlaceView } from "../../pages/places/PlaceView"

import { Users } from "../../pages/users/Users"
import { UserView } from "../../pages/users/UserView"

import { Tags } from "../../pages/tags/Tags"
import { TagView } from "../../pages/tags/TagView"

import { Countries } from "../../pages/countries/Countries"
import { CountryView } from "../../pages/countries/CountryView"

import { Collections } from "../../pages/collections/Collections"
import { CollectionView } from "../../pages/collections/CollectionView"

import { Settings } from "../../pages/settings/Settings"

import { PrivateRoute, PublicRoute } from "./routing"

export interface IRouteConfiguration {
  type: typeof PrivateRoute | typeof PublicRoute
  component: React.FC<any>
  path: string
  exact?: boolean
}

export const useGetRouteConfiguration = (): IRouteConfiguration[] => {
  return [...adminRouteConfigurations, ...generalRouteConfigurations]
}

export const generalRouteConfigurations: IRouteConfiguration[] = [
  // AUTH
  {
    path: "/signin",
    component: Signin,
    type: PublicRoute,
  },
  // ELSE - NOT FOUND
  {
    path: "/*",
    component: PageNotFound,
    type: PrivateRoute,
  },
]

export const adminRouteConfigurations: IRouteConfiguration[] = [
  {
    path: "/dashboard",
    component: Dashboard,
    type: PrivateRoute,
  },
  {
    path: "/import/:model",
    component: ImportModelView,
    type: PrivateRoute,
  },
  {
    path: "/settings",
    component: Settings,
    type: PrivateRoute,
  },

  // USERS
  {
    path: "/users/:id",
    component: UserView,
    type: PrivateRoute,
  },
  {
    path: "/users",
    component: Users,
    type: PrivateRoute,
  },

  // PLACES
  {
    path: "/places/:id",
    component: PlaceView,
    type: PrivateRoute,
  },
  {
    path: "/places",
    component: Places,
    type: PrivateRoute,
  },

  // COUNTRIES
  {
    path: "/countries/:id",
    component: CountryView,
    type: PrivateRoute,
  },
  {
    path: "/countries",
    component: Countries,
    type: PrivateRoute,
  },


  // TAGS
  {
    path: "/tags/:id",
    component: TagView,
    type: PrivateRoute,
  },
  {
    path: "/tags",
    component: Tags,
    type: PrivateRoute,
  },

  
  // COLLECTIONS
  {
    path: "/collections/:id",
    component: CollectionView,
    type: PrivateRoute,
  },
  {
    path: "/collections",
    component: Collections,
    type: PrivateRoute,
  },
  
]
