import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { AccountResponseDto, UserService } from "./../../app/service/user"

import { dateFormat } from "../../app/utils/index"

import { SplashButton } from "../../partial/button/SplashButton"
import { ProfileTop } from "../../partial/ProfileTop"

import { UserAnalytics } from "./UserAnalytics"

export const UserView = () => {
  const history = useHistory()
  const [user, setUser] = useState<AccountResponseDto>()
  const { id } = useParams<{ id: string }>()

  const [modal, setModal] = useState<{
    show: boolean
  }>({ show: false })

  useEffect(() => {
    fetch()
  }, [])

  const fetch = () => {
    new UserService().get(id).then((r) => {
      setUser(r.data)
    })
  }

  return (
    <>
      <div className="p-5">
        {user && (
          <ProfileTop
            profile={{ name: user.firstName + " " + user.lastName, cover: user.cover, title: "Created at: " + dateFormat(user.createdAt),}}
            right={ <span className="flex gap-3"> <SplashButton title="Back" icon="arrow-left-circle" onClick={() => { history.goBack() }} /> </span> }
          />
        )}

        {user && (
          <>
            {/* <div className="my-5">
              <FormHeader title="user Settings">
                <userSettings
                  user={user}
                  onSave={(user) => onSave(user)}
                />
              </FormHeader>
            </div> */}
            <div className="my-5">
              <UserAnalytics user={user} />
            </div>
          </>
        )}
      </div>
    </>
  )
}
